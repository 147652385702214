import React from 'react';
import instagram from '../../icons/Instagram.svg';
import youtube from '../../icons/Youtube.svg';

const primecolor = {
  color : '#0C41B8'
}

const Footer = () => (
  <footer className="m-4 md:mx-auto py-2 px-3 mt-12 mb-8 text-gray-800">
    <hr  />
    <div className="container md:flex -mx-3 m-4 md:mx-auto py-2 px-3 mt-12 mb-8 text-gray-800">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About GExSRE Youth Idea Competition 2021</h2>
        <p className="mt-5 md:w-3/4 text-justify">GE x SRE Youth Idea Competition 2021 is a medium for students to share solutions for renewable 
        energy development ideas in 4 main categories. Each team will have the opportunity to present their 
        ideas in the form of a White Paper for this competition.</p>
      </div><br />
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Social Media</h2>
        <div className="socmed flex gap-4 mt-4">
          <a href="https://www.instagram.com/geyic2021/" target="_blank"><img src={instagram} alt="" /></a>
          <a href="https://www.youtube.com/channel/UCYWScfXOsM19aPMBqUpoekA" target="_blank"><img src={youtube} alt="" /></a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;

import { Link } from 'gatsby';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '../Button/OutlinedButton';
import CompactLogo from '../Images/CompactLogo';

const Header = ({ withRegister }) => (
  <header className="sticky top-0 bg-white z-50 h-20 flex items-center justify-center">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-24 mr-3">
          <Link to="/">
            <CompactLogo />
          </Link>
        </div>
      </div>
      <div className="flex items-center mt-4 sm:mt-0">
        <AnchorLink href="#webinar" className="px-4">
          Webinar
        </AnchorLink>
        <a
          href="https://drive.google.com/drive/folders/1FHx1GcpBzhSGVv27huFQZu6Bpl0Uqrlr?usp=sharing"
          className="px-4"
          target="_blank"
          rel="norefferer"
        >
          FAQ
        </a>
        <a
          href="https://drive.google.com/drive/folders/1h23AKLrwr_ABajdTnvvefCri9waYYw9Z?usp=sharing"
          className="px-4"
          target="_blank"
          rel="norefferer"
        >
          Kit
        </a>
        <a
          href="https://drive.google.com/drive/folders/13Y0wPlJl6Vn0cempd65PjxLhikBh5hQP?usp=sharing"
          className="px-4"
          target="_blank"
          rel="norefferer"
        >
          Guidebook
        </a>
        {withRegister ? (
          <div className="hidden md:block">
            <Link to="/register">
              <Button className="text-sm rounded-full" size="sm">
                Register Now
              </Button>
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  </header>
);

export default Header;

import React from 'react';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children, withRegister=false }) => {
  return (
    <>
      <Header withRegister={withRegister} />
      <main className="text-gray-900">{children}</main>
      <Footer></Footer>
    </>
  );
};

export default Layout;
